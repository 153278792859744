import React from 'react';

const TermsAndConditions = () => {
    return (
        <div className="cont-term">
            <section className="section-term">
                <h2>Termini e Condizioni</h2>
                <p>Benvenuto nel sito web www.maurosergiodefeudis.it .</p>
                <p>Si prega di leggere attentamente i seguenti Termini e Condizioni prima di utilizzare questo sito web. Utilizzando questo sito web, accetti di essere vincolato dai seguenti Termini e Condizioni. Se non accetti questi Termini e Condizioni, ti preghiamo di non utilizzare questo sito web.</p>
            </section>
            <section className="section-term">
                <h2>Utilizzo del Sito</h2>
                <p>Il sito web www.maurosergiodefeudis.it è un sito web vetrina utilizzato esclusivamente a scopo informativo. Tutti i contenuti presenti sul sito, inclusi testi, immagini e grafiche, sono forniti a scopo illustrativo e informativo.</p>
                <p>L'utente accetta di utilizzare il sito web www.maurosergiodefeudis.it solo per scopi leciti e in conformità con questi Termini e Condizioni, nonché con tutte le leggi e regolamenti applicabili.</p>
            </section>
            <section className="section-term">
                <h2>Proprietà Intellettuale</h2>
                <p>Tutti i contenuti presenti sul sito web www.maurosergiodefeudis.it, inclusi testi, immagini, grafiche, loghi e marchi, sono di proprietà esclusiva di www.maurosergiodefeudis.it o dei rispettivi proprietari e sono protetti dalle leggi sul copyright e sulla proprietà intellettuale.</p>
                <p>È vietato riprodurre, distribuire, modificare o utilizzare in qualsiasi modo i contenuti del sito web www.maurosergiodefeudis.it senza l'autorizzazione esplicita scritta di  tua www.maurosergiodefeudis.it o dei rispettivi proprietari.</p>
            </section>
            <section className="section-term">
                <h2>Limitazione di Responsabilità</h2>
                <p>www.maurosergiodefeudis.it si impegna a fornire informazioni accurate e aggiornate sul sito web www.maurosergiodefeudis.it, tuttavia non offre alcuna garanzia né si assume alcuna responsabilità per l'accuratezza, l'idoneità o la completezza delle informazioni fornite.</p>
                <p>In nessun caso www.maurosergiodefeudis.it sarà responsabile per danni diretti, indiretti, accidentali, speciali o consequenziali derivanti dall'utilizzo o dall'incapacità di utilizzare il sito web www.maurosergiodefeudis.it o dai contenuti presenti sul sito.</p>
            </section>
            <section className="section-term">
                <h2>Modifiche ai Termini e Condizioni</h2>
                <p>www.maurosergiodefeudis.it si riserva il diritto di modificare questi Termini e Condizioni in qualsiasi momento senza preavviso. Le modifiche entreranno in vigore immediatamente dopo la pubblicazione sul sito web www.maurosergiodefeudis.it. Si consiglia agli utenti di consultare regolarmente questa pagina per verificare eventuali modifiche.</p>
                <p>Continuando ad utilizzare il sito web www.maurosergiodefeudis.it dopo la pubblicazione delle modifiche, l'utente accetta e riconosce di essere vincolato ai Termini e Condizioni modificati.</p>
            </section>
            <section className="section-term">
                <h2>Contatti</h2>
                <p>Per domande o ulteriori informazioni sui Termini e Condizioni del sito web www.maurosergiodefeudis.it.</p>
            </section>
            <footer className="footer-term">
                <p>Ultimo aggiornamento: 01.04.2024.</p>
            </footer>
        </div>
    );
};

export default TermsAndConditions;
