import React from "react";
import { Link } from "react-router-dom";


function ChiSonoC() {
  return (
    <div className="container">
      <h1 className="title">
        BENVENUTO NEL MIO 🌏 DIGITALE ! <br />Il mio nome è{" "}
        <span className="yellow">MAURO! 👨🏻‍💻</span>
        <br />
      </h1>
      <div className="center-content">
        <div className="content-container">
          <div className="content">
            <div className="qa-container">
              <div className="question">
                <p>
                  <span className="highlight"> LA MIA PASSIONE?</span>
                </p>
              </div>

              <div className="answer">
                <p>
                  Da sempre affascinato dal mondo della tecnologia, con
                  particolare interesse nello sviluppo di app e software. Allo
                  stesso tempo, ho una grande passione per le automobili,
                  ammirando come l'evoluzione tecnologica stia trasformando
                  positivamente la nostra vita quotidiana.
                </p>
              </div>

              <div className="question">
                <p>
                  <span className="highlight">DI COSA MI OCCUPO?</span>
                </p>
              </div>
              <div className="answer">
                <p>
                  Mi occupo della realizzazione di app mobile, siti web e
                  e-commerce, offrendo un approccio completo e di alta
                  qualità, coniugando la mia passione per la tecnologia con
                  l'amore per l'informatica.
                </p>
              </div>
              <hr></hr>
              <div className="pConsulenza">
                <p>
                  Scopri i miei servizi qui
                <Link to="/servizi">
                  <button className="pConsulenzaButton">SERVIZI ⚙️</button>
                </Link> 
                </p>

              </div>
            </div>
          </div>
        </div>
        <div className="img-mauroChiSono">
          <img src="mauro.png" alt="imageMauro" />
        </div>
      </div>

    </div>
  );
}

export default ChiSonoC;
