import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";


const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [hasInteractedWithBanner, setHasInteractedWithBanner] = useState(false);

  useEffect(() => {
    const hasConsented = localStorage.getItem('cookieConsent');
    if (!hasConsented) {
      setShowBanner(true);
    } else {
      setHasInteractedWithBanner(true);
    }
  }, []);

  const updateConsentPreferences = (consent) => {
    const consentPreferences = JSON.parse(localStorage.getItem('consentPreferences')) || {};
    const userId = 'user_id';
    consentPreferences[userId] = {
      consent,
      timestamp: new Date().toISOString(),
    };

    localStorage.setItem('consentPreferences', JSON.stringify(consentPreferences));
  };

  const handleConsent = (consent) => {
    updateConsentPreferences(consent);
    setShowBanner(false);
    setHasInteractedWithBanner(true);
  };

  const handleOpenBanner = () => {
    setShowBanner(true);
  };

  return (
    <div>
      {showBanner && (
        <div className="cookie-banner">
          <p>
            Questo sito utilizza i cookie per migliorare l'esperienza dell'utente.
            Cliccando su "Accetta", acconsenti all'uso dei cookie in conformità con la nostra   &nbsp;
            <div>      
              <Link to="/informativa-policy"> Informativa sui Cookie </Link>
|
              <Link to="/privacy-policy"> Privacy policy </Link>

            </div>
          </p>
          <div>
            <button onClick={() => handleConsent(true)}>Accetta</button>
            <button onClick={() => handleConsent(false)}>Rifiuta</button>
            <button onClick={() => setShowBanner(false)}>Nascondi banner</button>
          </div>
        </div>
      )}
      {!hasInteractedWithBanner && (
        <div className="cookie-preferences-button-container">
          <button onClick={handleOpenBanner}>Modifica Preferenze Cookie</button>
        </div>
      )}
    </div>
  );
};

export default CookieBanner;
