import React from "react";
import ChiSonoC from "../components/ChiSonoC";

function ChiSono() {
  return (
    <div>
      <ChiSonoC />
    </div>
  );
}

export default ChiSono;
