import React from "react";
import {
  AiOutlineHome,
  AiOutlineFundProjectionScreen} from "react-icons/ai";
import { FiUser, FiFileText } from "react-icons/fi";

import { Link } from "react-router-dom";
function MobileNav({ showNav, setShowNav }) {
  return (
    <div
      className={
        showNav
          ? "fixed h-80 bg-gradient-to-r from-blue-900 to-blue-700  w-full top-[53px] left-0 rounded-b-md border- border-t-0 border-purple-700 z-50 transition-all duration-[500ms] overflow-hidden"
          : "fixed h-0 bg-gradient-to-r from-blue-600 to-blue-900  w-full top-[54px] left-0 rounded-b-md  z-50 transition-all duration-[500ms] overflow-hidden"
      }
    >
      <div className=" block items-center justify-center text-white">
        <ul className="flex gap-8 flex-col mt-9">
          <li className="relative group">
            <Link
              to={"/"}
              className="flex gap-1 items-center justify-center cursor-pointer text-base font-bold relative"
              onClick={() => setShowNav(false)}
            >
              <AiOutlineHome fontSize={30} />
              <span className="">Home</span>
            </Link>
          </li>
          <li className="relative group">
            <Link
              to={"/chi-sono"}
              className="flex gap-1 items-center justify-center cursor-pointer text-base font-bold relative"
              onClick={() => setShowNav(false)}
            >
              <FiUser fontSize={30} />
              <span className="">Chi sono</span>
            </Link>
          </li>
          <li className="relative group">
            <Link
              to={"/servizi"}
              className="flex gap-1 items-center justify-center cursor-pointer text-base font-bold relative"
              onClick={() => setShowNav(false)}
            >
              <AiOutlineFundProjectionScreen fontSize={30} />
              <span className="">Servizi</span>
            </Link>
          </li>
          <li className="relative group">
            <Link
              to={"/contatti"}
              className="flex gap-1 items-center justify-center cursor-pointer text-base font-bold relative"
              onClick={() => setShowNav(false)}
            >
              <FiFileText fontSize={30} />
              <span className="">Contatti</span>
            </Link>
          </li>
        </ul>

      
     
      </div>
    </div>
  );
}

export default MobileNav;
