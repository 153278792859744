
import React from "react";
import { Link } from "react-router-dom";

function Homepage() {
  return (

    <div className="backgroundSfondo">
      <div className="divHome">
        <h1 className="h1Benvenuto"> Benvenuto nel mio sito web </h1>
        <img src="https://media.tenor.com/SNL9_xhZl9oAAAAi/waving-hand-joypixels.gif"
          alt="Mano che saluta"
          className="manoSaluta" />

        <h2 className="h2iMdeveloper">
          I'm a <p className="P-developer">DEVELOPER</p>
        </h2>

        <div className="img-home">
          <img src="home-4b.png" alt="imageMauro" />
          <div>
            <Link to="/chi-sono" className="link-scopri">Scopri di più qui!</Link>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Homepage;



