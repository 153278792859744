import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="divFooter">
      <div className="footerContent">
        <p>
          Copyright © 2024        
          <img src="logoFooter.png" alt="icona Mauro" className="footerImage" />
          MsDf 
        </p>
      </div>
      <div className="terms">
        <Link to="/terms">Termini e Condizioni</Link>
      </div>
    </div>
  );
}

export default Footer;

