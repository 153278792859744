function CookiePolicy() {
  return (
    <div className="containerPolicy">
      <h1>Informativa sui Cookie</h1>
      <p>
        <strong>Cosa sono i cookie?</strong><br />
        I cookie sono piccoli file di testo che vengono memorizzati sul tuo dispositivo (computer, smartphone, tablet) quando visiti un sito web. I cookie possono essere utilizzati per diversi scopi, tra cui il salvataggio delle tue preferenze, l'analisi del comportamento degli utenti sul sito e la personalizzazione dei contenuti mostrati.
      </p>
      <p>
        <strong>Tipologie di Cookie</strong><br />
        Il nostro sito web utilizza diverse tipologie di cookie:
        <ul>
          <li>Cookie Tecnici</li>
          <li>Cookie di Analisi</li>
          <li>Cookie di Funzionalità</li>
        </ul>
      </p>
      <p>
        <strong>Finalità dei Cookie</strong><br />
        I cookie utilizzati sul nostro sito web hanno le seguenti finalità:
        <ul>
          <li>Memorizzare le tue preferenze di navigazione.</li>
          <li>Ottimizzare l'esperienza di navigazione.</li>
          <li>Analizzare l'utilizzo del sito e raccogliere dati statistici.</li>
          <li>Fornire contenuti personalizzati e pubblicità mirate.</li>
          <li>Assicurare il corretto funzionamento del sito web.</li>
        </ul>
      </p>
      <p>
        <strong>Consenso all'Utilizzo dei Cookie</strong><br />
        Al primo accesso al nostro sito web, ti viene chiesto di accettare l'utilizzo dei cookie tramite un banner dedicato. Puoi accettare o rifiutare i cookie tramite le opzioni fornite nel banner. Puoi anche gestire le tue preferenze sui cookie attraverso le impostazioni del tuo browser.
      </p>
      <p>
        <strong>Gestione dei Cookie</strong><br />
        Puoi gestire i cookie sul tuo dispositivo attraverso le impostazioni del tuo browser. Puoi eliminare i cookie già presenti e impedire l'installazione di nuovi cookie. Tieni presente che la disabilitazione dei cookie potrebbe influenzare alcune funzionalità del sito web.
      </p>
      <p>
        <strong>Contatti</strong><br />
        Per eventuali domande o richieste riguardanti l'utilizzo dei cookie sul nostro sito web, puoi contattarci utilizzando le informazioni di contatto fornite nella nostra pagina dei contatti.
      </p>
    </div>
  );
}

export default CookiePolicy;