import React from "react";
import { Typewriter } from "react-simple-typewriter";
function RettangoloServizi() {
  return (
    <div>
       <Typewriter
                    words={["Siti-Web 🌐", "E-commerce 🛒", "App-mobile 🤳", "Menù digitali 🍴", "Gestionali 📑", "Loghi🖼️"]}
                    loop={0}
                    cursor
                    cursorStyle="|"
                    typeSpeed={70}
                    deleteSpeed={50}
                    delaySpeed={1000}
                />
    </div>
  );
}

export default RettangoloServizi;
