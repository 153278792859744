import React  from "react";
import   ContattiC from "../components/ContattiC.js";


function Contatti() {

  return (
    <div >
      <ContattiC/>
    </div>
  );
}

export default Contatti;
