import React, { useState } from 'react';

const FormContatti = () => {
  const [formData, setFormData] = useState({ name: '', email: '', phone: '', message: '' });
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Validazione per il campo del numero di telefono
    if (name === 'phone') {
      if (!value.trim() || /^\d+$/.test(value)) {
        setFormData({ ...formData, [name]: value });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!formData.name.trim()) {
      errors.name = '⚠️ Il campo NOME è obbligatorio ⚠️';
      isValid = false;
    }

    if (!formData.email.trim()) {
      errors.email = '⚠️ Il campo EMAIL è obbligatorio ⚠️';
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = '⚠️ L\'indirizzo email non è valido ⚠️';
      isValid = false;
    }

    if (!/^\d+$/.test(formData.phone)) {
      errors.phone = '⚠️ Si prega di inserire solo numeri nel campo TELEFONO ⚠️';
      isValid = false;
    }

    if (!formData.message.trim()) {
      errors.message = '⚠️ Il campo MESSAGGIO è obbligatorio ⚠️';
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const formDataToSend = new FormData();
      formDataToSend.append("access_key", "13ea60a2-ba68-450e-8821-131f85a8b089");
      formDataToSend.append("name", formData.name);
      formDataToSend.append("email", formData.email);
      formDataToSend.append("phone", formData.phone);
      formDataToSend.append("message", formData.message);

      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formDataToSend
      }).then((res) => res.json());

      if (res.success) {
        setSubmitted(true);
        setFormData({ name: '', email: '', phone: '', message: '' });
        setShowModal(true);
      } else {
      }
    }
  };

  return (
    <div className="contatti">
      <div className="contatti-form">
        <h3 className='h3contatti-form'>COMPILA IL FORM PER ULTERIORI INFO ✍🏼 </h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="*Nome" />
            {errors.name && <div className="error-message">{errors.name}</div>}
          </div>
          
          <div className="form-group">
            <input type="text" name="phone" value={formData.phone} onChange={handleChange} placeholder="Telefono" />
            {errors.phone && <div className="error-message">{errors.phone}</div>}
          </div>

          <div className="form-group">
            <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="*Email" />
            {errors.email && <div className="error-message">{errors.email}</div>}
          </div>

          <div className="form-group">
            <textarea name="message" value={formData.message} onChange={handleChange} placeholder="*Messaggio"></textarea>
            {errors.message && <div className="error-message">{errors.message}</div>}
          </div>
          
          <button type="submit" disabled={submitted}>Invia</button>
        </form>
        {submitted && <div className="success-message">'</div>}
      </div>

      {/* Finestra modale */}
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setShowModal(false)}>&times;</span>
            <p>Il messaggio è stato inviato con successo!✅</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormContatti;