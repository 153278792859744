import React from 'react';
import RettangoloServizi from './RettangoloServizi';
import { Link } from "react-router-dom";

const ServiziC = () => {
    return (
        <div className="containerServizi">
            <div className="title-wrapper">
                <h1 className="servizi"> SERVIZI </h1>
                <div className="offerti">OFFERTI</div>
                <div className="rettangolo-home-servizi">
                    <RettangoloServizi />
                </div>
                </div>

                <div className="img-servizi">
    <img src="serviziImg.png"  alt="Servizi digitali su diversi dispositivi" />
    <p className="p-servizi">
        Scopri i miei servizi digitali su misura, progettati per soddisfare
        ogni tua esigenza.<br></br> Dalla creazione di siti web ad App per dispositivi Andorid e iOS,
        offrendo soluzioni personalizzate per potenziare la tua presenza online.
        <br />
        Per una consulenza gratuita e trovare la soluzione perfetta per te
        <Link to="/contatti">
            <button className="pConsulenzaButton">CONTATTAMI ✉️</button>
        </Link>
    </p>
</div>
        </div>
    );
};

export default ServiziC;
