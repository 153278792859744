import React from "react";
import ServiziC from "../components/ServiziC";

function Servizi() {
  return (
    <ServiziC/>
    
      
  );
}

export default Servizi;
