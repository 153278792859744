import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="cont-privacy">
      <h1 className="h1-policy">Privacy Policy</h1>
      <p>
        La tua privacy è importante per noi. È nostra politica rispettare la tua privacy riguardo a qualsiasi informazione che possiamo raccogliere mentre operiamo il nostro sito web. Di seguito ti forniamo informazioni riguardanti il tipo di dati che potremmo raccogliere e come li useremo e proteggeremo.
      </p>
      <h2 className="privacy-section-title">Dati Personali Raccolti</h2>
      <p>
        Il nostro sito web potrebbe raccogliere dati personali tramite form di contatto,interazioni sociali o altri mezzi, quali:
      </p>
      <ul>
        <li>Dati di contatto come nome, email, numero di telefono;</li>
        <li>Altri dati personali che potresti condividere con noi durante l'utilizzo del sito.</li>
      </ul>
      <h2 className="h2-policy">Utilizzo dei Dati</h2>
      <p>
        Utilizziamo i dati raccolti per scopi vari, inclusi:
      </p>
      <ul>
        <li>Fornire e mantenere il nostro servizio;</li>
        <li>Personalizzare l'esperienza dell'utente;</li>
        <li>Invio di comunicazioni promozionali;</li>
        <li>Analisi o miglioramento dei nostri servizi.</li>
      </ul>
      <h2 className="privacy-section-title">Contattaci</h2>
      <p>
        Se hai domande o dubbi riguardo alla nostra Privacy Policy, non esitare a contattarci utilizzando le informazioni di contatto fornite sul nostro sito web.
      </p>
      <br></br>
      <p>
        Ultimo aggiornamento: 01.04.2024
      </p>
    </div>
  );
};

export default PrivacyPolicy;

