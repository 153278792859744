import React from "react";
import HomepageC from "../components/HomepageC.js";

function Home() {
  return (
    <div>
      <HomepageC />
    </div>
  );
}

export default Home;
