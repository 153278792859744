import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import ChiSono from "./pages/ChiSono";
import Servizi from "./pages/Servizi";
import Contatti from "./pages/Contatti";
import Header from "./components/Header";
import Footer from "./components/Footer";
import CookieBanner from "./components/CookieBanner ";
import CookiePolicy from "./components/CookiePolicy";
import TermsAndConditions from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import "./footer.css";
import "./header.css";
import "./homepage.css";
import "./cookies.css";
import "./contatti.css";
import "./chi-sono.css";
import "./servizi.css";
import "./cookie-policy.css";
import "./terms.css";
import "./policy.css";





function App() {
  return (
    <div class=" bg-gradient-to-l from-slate-900 via-blue-900 to-slate-900">
      <Header />
      <CookieBanner/>
      
      <Routes>  
      <Route path="/informativa-policy" element={<CookiePolicy/>} />
      <Route path="/terms" element={<TermsAndConditions/>} />
      <Route path="/privacy-policy" element={<PrivacyPolicy/>} />

        <Route path="/" element={<Home />} />
        <Route path="/chi-sono" element={<ChiSono />} />
        <Route path="/servizi" element={<Servizi />} />
        
        <Route path="/contatti" element={<Contatti />} />

      </Routes>
      <Footer />
    </div>
  );
}

export default App;
