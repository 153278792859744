import React from 'react';
import FormContatti from './FormContatti';
import { Link } from "react-router-dom";

const ContattiC = () => {
  return (
    <div className="backgroundSfondoContatti">
      <div className="contatti-info">
        <h1 className="h1Contatti">CONTATTI</h1>
        <div className="social-container">
          <a href="mailto:masedefe@hotmail.com" className="social-link">
            <img src="gmail.png" alt="gmail" className="social-icon" />
            <span className="social-text">E-mail</span>
          </a>

          <a href="https://it.linkedin.com/in/mauro-sergio-de-feudis-903122280" className="social-link">
            <img src="linkedin.png" alt="gmail" className="social-icon" />
            <span className="social-text">Linkedin </span>
          </a>
        </div>

        <div className="pContattiConsulenza"> 
          <Link to="/servizi">
            <button className="pConsulenzaButton">Clicca qui👉🏽</button>
          </Link>
          <p className="text-center"> per visionare i servizi a disposizione</p>
        </div>
      </div>

      {/* Form Contatti */}
      <div className="contatti-form">
        <FormContatti />
      </div>
    </div>
  );
};

export default ContattiC;
