import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineHome, AiOutlineFundProjectionScreen } from "react-icons/ai";
import { FiUser, FiFileText } from "react-icons/fi";
import { RiMenu3Fill } from "react-icons/ri";
import { MdClose } from "react-icons/md";
import MobileNav from "./MobileNav";

function Header() {
  const navigate = useNavigate();
  const [showNav, setShowNav] = useState(false);
  const [scroll, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={scroll ? "scrolled-header" : "header"}>
      <h1 className="brand-text" onClick={() => navigate("/")}>
          {"{Mauro Sergio De Feudis}"}
        </h1>
      <div className="nav-links">
        <ul>
          <li className="nav-item">
            <Link to={"/"} className="nav-link">
              <AiOutlineHome className="small-icon" />
              <span>Home</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to={"/chi-sono"} className="nav-link">
              <FiUser className="small-icon" />
              <span>Chi sono</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to={"/servizi"} className="nav-link">
              <AiOutlineFundProjectionScreen className="small-icon" />
              <span>Servizi</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link to={"/contatti"} className="nav-link">
              <FiFileText className="small-icon" />
              <span>Contatti</span>
            </Link>
          </li>
        </ul>
      </div>
      <span>
        <div className="h-full">
          {showNav ? (
            <MdClose
              className="small-icon menu-icon"
              onClick={() => setShowNav(!showNav)}
            />
          ) : (
            <RiMenu3Fill
              className="small-icon menu-icon"
              onClick={() => setShowNav(!showNav)}
            />
          )}
          <MobileNav showNav={showNav} setShowNav={setShowNav} />
        </div>
      </span>
    </div>
  );
}

export default Header;
